import React from 'react';

// Utilities
import classnames from 'classnames';
import styled from '@emotion/styled';
import { propTypes } from 'shapes/giphy-embed';

const EmbedWrapper = styled.div`
    width: 100%;
    height: 0;
    padding-bottom: 56%;
    position: relative;
`;

const GiphyEmbed = ({ title, source, classNames }) => (
    <EmbedWrapper>
        <iframe
            title={title}
            src={source}
            width="100%"
            height="100%"
            style={{ position: 'absolute' }}
            frameBorder="0"
            className={classnames(classNames)}
            allowFullScreen
        />
    </EmbedWrapper>
);

GiphyEmbed.propTypes = propTypes;

GiphyEmbed.defaultProps = {
    classNames: 'giphy-embed',
};

export default GiphyEmbed;
