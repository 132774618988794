import React from 'react';

// Components
import Layout from 'components/Layout';
import GiphyEmbed from 'components/Giphy-Embed';

const NotFoundPage = () => (
    <Layout>
        <div className="page-content">
            <h1>Ugh</h1>
            <p>
                I done goofed.{' '}
                <a href="https://twitter.com/intent/tweet?text=@abrad45">
                    Tweet this link to me
                </a>
                , and tell me how you got here, would you?
            </p>
            <GiphyEmbed
                title="404: Link Shield Surfing Fail"
                source="https://giphy.com/embed/hmazgC27w9vtC"
            />
        </div>
    </Layout>
);

export default NotFoundPage;
